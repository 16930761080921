import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useAppStore } from '../lib/store';
import Head from 'next/head';
import { fetchInstances } from '../helpers/utils';
import { useRouter } from 'next/router';
import Script from 'next/script';
import * as gtag from '../helpers/gtag';
import { GA_TRACKING_ID } from '../../AppConstants';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  const { setContractInstances } = useAppStore();
  useEffect(() => {
    const info = fetchInstances();
    setContractInstances(info);
  }, []);
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      {/* eslint-disable-next-line @next/next/inline-script-id */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
        }}
      />
      <Head>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={'/favicon.ico'} />
        <title>pSTAKE | Migration Utility</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Migration Utility for pSTAKE erc20 assets"
        />
        <meta
          name="keywords"
          content="Migration Utility, pstake, $pstake, cosmos, persistence, xprt, atom, stkatom"
        />
      </Head>
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Component {...pageProps} />
      </>
    </>
  );
}

export default MyApp;
