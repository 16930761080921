import _ from 'lodash';
import Axios from 'axios';
import { CHAIN, MIGRATION_ADMIN, STAKE_LP, TOKEN_ABI } from './config';
import { BigNumber, ethers, utils } from 'ethers';
import { Decimal } from '@cosmjs/math';
import { instanceTypes, tokenType } from '../lib/slices/walletSlice';
import { Scope } from '@sentry/nextjs';
import * as Sentry from '@sentry/nextjs';
import WETH from '../abi_mainnet/WETH.json';
import { balances } from '../lib/slices/balanceSlice';

export const STK_ATOM_CVALUE_API =
  'https://api.persistence.one/pstake/stkatom/c_value';

export const removeCommas = (str: any) =>
  _.replace(str, new RegExp(',', 'g'), '');

const reverseString = (str: any) =>
  removeCommas(_.toString(_.reverse(_.toArray(str))));

const recursiveReverse = (input: any): string => {
  if (_.isArray(input))
    return _.toString(_.reverse(_.map(input, (v) => recursiveReverse(v))));
  if (_.isString()) return reverseString(input);
  return reverseString(`${input}`);
};

export const sixDigitsNumber = (value: string, length = 6) => {
  let inputValue = value.toString();
  if (inputValue.length >= length) {
    return inputValue.substring(0, length);
  } else {
    const stringLength = length - inputValue.length;
    let newString = inputValue;
    for (let i = 0; i < stringLength; i++) {
      newString += '0';
    }
    return newString;
  }
};

export const formatNumber = (v = 0, size = 3, decimalLength = 6) => {
  let str = `${v}`;
  if (!str) return 'NaN';
  let substr = str.split('.');
  if (substr[1] === undefined) {
    let newString = '0';
    for (let i = 1; i < decimalLength; i++) {
      newString += '0';
    }
    substr.push(newString);
  } else {
    substr[1] = sixDigitsNumber(substr[1], decimalLength);
  }
  str = reverseString(substr[0]);
  const regex = `.{1,${size}}`;
  const arr = str.match(new RegExp(regex, 'g'));
  return `${recursiveReverse(arr)}${substr[1] ? `.${substr[1]}` : ''}`;
};

export const stringTruncate = (str: string, length = 7) => {
  if (str.length > 30) {
    return (
      str.substring(0, length) +
      '...' +
      str.substring(str.length - length, str.length)
    );
  }
  return str;
};

export const truncateToFixedDecimalPlaces = (
  num: number,
  decimalPlaces = 6
) => {
  const regexString = '^-?\\d+(?:\\.\\d{0,dp})?';
  const regexToMatch = regexString.replace('dp', `${decimalPlaces}`);
  const regex = new RegExp(regexToMatch);
  const matched = num.toString().match(regex);
  if (matched) {
    return parseFloat(matched[0]);
  }
  return 0;
};

export const emptyFunc = () => ({});

export const decimalize = (valueString: string | number, decimals = 6) => {
  let truncate: number;
  if (typeof valueString === 'string') {
    truncate = Number(valueString);
  } else {
    truncate = valueString;
  }
  return Decimal.fromAtomics(
    Math.trunc(truncate!).toString(),
    decimals
  ).toString();
};

export const unDecimalize = (valueString: string | number, decimals = 6) => {
  return Decimal.fromUserInput(valueString.toString(), decimals).atomics;
};

export const fetchKeplrAddress = async (chainId: string) => {
  if (!window.getOfflineSigner || !window.keplr) {
    throw new Error('install keplr extension');
  }
  await window.keplr.enable(chainId);
  const offlineSigner = window.getOfflineSigner(chainId);
  const accounts = await offlineSigner.getAccounts();
  return accounts[0].address;
};

export const getAbiJson = (abiJSONName: string, token: tokenType) => {
  let artifactJSON;
  if (process.env.NEXT_PUBLIC_REACT_APP_ENV === 'Testnet') {
    if (token === 'stkXPRT') {
      artifactJSON = require(`../abi_testnet/stkXprt/${abiJSONName}.json`);
    } else {
      artifactJSON = require(`../abi_testnet/stkAtom/${abiJSONName}.json`);
    }
  } else if (
    process.env.NEXT_PUBLIC_REACT_APP_ENV === 'Staging' ||
    process.env.NEXT_PUBLIC_REACT_APP_ENV === 'Mainnet'
  ) {
    if (token === 'stkXPRT') {
      artifactJSON = require(`../abi_mainnet/stkXprt/${abiJSONName}.json`);
    } else {
      artifactJSON = require(`../abi_mainnet/stkAtom/${abiJSONName}.json`);
    }
  }
  return artifactJSON;
};

export const fetchInstance = (contractName: string, tokenType: tokenType) => {
  const ethereum = window!.ethereum!;
  if (ethereum!) {
    const ethProvider = new ethers.providers.Web3Provider(ethereum);
    const signer = ethProvider.getSigner();
    const contractAddress =
      CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!]['CONTRACT_ADDRESSES'][
        tokenType
      ][contractName];
    const contractABI = getAbiJson(contractName, tokenType);
    return new ethers.Contract(
      contractAddress,
      contractABI.abi ? contractABI.abi : contractABI,
      signer
    );
  }
};

// @ts-ignore
export const fetchInstances = (): instanceTypes => {
  // migrationAdmin Contract Instances
  const atomMigrationInstance = fetchInstance(MIGRATION_ADMIN, 'stkATOM');
  const xprtMigrationInstance = fetchInstance(MIGRATION_ADMIN, 'stkXPRT');

  // lpToken Contract Instances
  const atomLpTokenInstance = fetchInstance(TOKEN_ABI, 'stkATOM');
  const xprtLpTokenInstance = fetchInstance(TOKEN_ABI, 'stkXPRT');

  // stakeLp Contract Instances
  const atomStakeLpInstance = fetchInstance(STAKE_LP, 'stkATOM');
  const xprtStakeLpInstance = fetchInstance(STAKE_LP, 'stkXPRT');

  return {
    migrationAdmin: {
      stkATOM: atomMigrationInstance,
      stkXPRT: xprtMigrationInstance
    },
    lpTokens: {
      stkATOM: atomLpTokenInstance,
      stkXPRT: xprtLpTokenInstance
    },
    stakeLp: {
      stkATOM: atomStakeLpInstance,
      stkXPRT: xprtStakeLpInstance
    }
  };
};

export const fetchTokenBalances = async (
  instances: instanceTypes,
  address: string
) => {
  try {
    const xprtBalances = await instances.migrationAdmin.stkXPRT.users(address);
    const cosmosBalances = await instances.migrationAdmin.stkATOM.users(
      address
    );
    return {
      stkATOM: Number(decimalize(cosmosBalances['stkATOM'].toString(), 6)),
      pATOM: Number(decimalize(cosmosBalances['pATOM'].toString(), 6)),
      stkXPRT: Number(decimalize(xprtBalances['stkXPRT'].toString(), 6)),
      pXPRT: Number(decimalize(xprtBalances['pXPRT'].toString(), 6))
    };
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel('fatal');
    customScope.setTags({
      'Error while fetching token balances': address
    });
    genericErrorHandler(e, customScope);
    return {
      stkATOM: 0,
      pATOM: 0,
      stkXPRT: 0,
      pXPRT: 0
    };
  }
};

export const fetchEthShares = async (
  instances: instanceTypes,
  address: string,
  stkAtomLPAmount: BigNumber,
  stkXprtLPAmount: BigNumber
) => {
  try {
    // get weth contract instance
    const ethereum = window!.ethereum!;
    if (ethereum!) {
      const ethProvider = new ethers.providers.Web3Provider(ethereum);
      const signer = ethProvider.getSigner();
      const weth = new ethers.Contract(
        '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        WETH,
        signer
      );
      let ethBalance, lpSupply;
      // calculate ETH share
      // ETH Share = LP tokens(bonded + unbonded) * (WETH balance of LP contract) / LP token total supply

      ethBalance = await weth.balanceOf(instances.lpTokens.stkATOM.address);
      lpSupply = await instances.lpTokens.stkATOM.totalSupply();
      const atomETHShare = stkAtomLPAmount.mul(ethBalance).div(lpSupply);

      ethBalance = await weth.balanceOf(instances.lpTokens.stkXPRT.address);
      lpSupply = await instances.lpTokens.stkXPRT.totalSupply();
      const xprtETHShare = stkXprtLPAmount.mul(ethBalance).div(lpSupply);

      return {
        stkATOM: atomETHShare,
        stkXPRT: xprtETHShare
      };
    }
    return {
      stkATOM: ethers.utils.parseEther('0'),
      stkXPRT: ethers.utils.parseEther('0')
    };
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel('fatal');
    customScope.setTags({
      'Error while fetching ETH share': address
    });
    genericErrorHandler(e, customScope);
    return {
      stkATOM: ethers.utils.parseEther('0'),
      stkXPRT: ethers.utils.parseEther('0')
    };
  }
};

export const fetchLpTokenBalances = async (
  instances: instanceTypes,
  address: string
) => {
  try {
    const atomBalances = await instances.lpTokens.stkATOM.balanceOf(address);
    const xprtBalances = await instances.lpTokens.stkXPRT.balanceOf(address);
    return {
      stkATOM: atomBalances,
      stkXPRT: xprtBalances
    };
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel('fatal');
    customScope.setTags({
      'Error while fetching lpToken balances': address
    });
    genericErrorHandler(e, customScope);
    return {
      stkATOM: ethers.utils.parseEther('0'),
      stkXPRT: ethers.utils.parseEther('0')
    };
  }
};

export const fetchLpTokenAllowances = async (
  instances: instanceTypes,
  address: string
) => {
  const stkATOMStakeLpContractAddress =
    CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!]['CONTRACT_ADDRESSES'][
      'stkATOM'
    ].StakeLP;
  const stkXPRTStakeLpContractAddress =
    CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!]['CONTRACT_ADDRESSES'][
      'stkXPRT'
    ].StakeLP;
  try {
    const atomAllowances = await instances.lpTokens.stkATOM.allowance(
      address,
      stkATOMStakeLpContractAddress
    );
    const xprtAllowances = await instances.lpTokens.stkXPRT.allowance(
      address,
      stkXPRTStakeLpContractAddress
    );
    return {
      stkATOM: atomAllowances,
      stkXPRT: xprtAllowances
    };
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel('fatal');
    customScope.setTags({
      'Error while fetching lpToken allowance': address
    });
    genericErrorHandler(e, customScope);
    return {
      stkATOM: ethers.utils.parseEther('0'),
      stkXPRT: ethers.utils.parseEther('0')
    };
  }
};

export const fetchBondedLpTokenBalances = async (
  instances: instanceTypes,
  address: string
) => {
  try {
    const atomLpAddress =
      CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!]['CONTRACT_ADDRESSES'][
        'stkATOM'
      ]['LPAddress'];
    const xprtLpAddress =
      CHAIN[process.env.NEXT_PUBLIC_REACT_APP_ENV!]['CONTRACT_ADDRESSES'][
        'stkXPRT'
      ]['LPAddress'];
    // @ts-ignore
    const atomBalances = await instances.stakeLp.stkATOM._lpBalance(
      atomLpAddress,
      address
    );
    // @ts-ignore
    const xprtBalances = await instances.stakeLp.stkXPRT._lpBalance(
      xprtLpAddress,
      address
    );
    return {
      stkATOM: atomBalances,
      stkXPRT: xprtBalances
    };
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel('fatal');
    customScope.setTags({
      'Error while fetching Bonded lpToken balances': address
    });
    genericErrorHandler(e, customScope);
    return {
      stkATOM: ethers.utils.parseEther('0'),
      stkXPRT: ethers.utils.parseEther('0')
    };
  }
};

export const getExchangeRate = async (): Promise<number> => {
  try {
    const res = await Axios.get(STK_ATOM_CVALUE_API);
    if (res && res.data) {
      return Number(res.data);
    }
    return 1;
  } catch (e) {
    const customScope = new Scope();
    customScope.setLevel('fatal');
    customScope.setTags({
      'Error while fetching exchange rate': STK_ATOM_CVALUE_API
    });
    genericErrorHandler(e, customScope);
    return 1;
  }
};

export const genericErrorHandler = (e: any, scope = new Scope()) => {
  console.error(e);
  Sentry.captureException(e, scope);
};
