import { create } from 'zustand';
import { createTxnSlice, TxnSlice } from './slices/txnSlice';
import { createWalletSlice, WalletSlice } from './slices/walletSlice';
import { createBalanceSlice, BalanceSlice } from './slices/balanceSlice';
import { mountStoreDevtool } from 'simple-zustand-devtools';

type StoreState = TxnSlice & WalletSlice & BalanceSlice;

export const useStore = create();

export const useAppStore = create<StoreState>()((...a) => ({
  ...createTxnSlice(...a),
  ...createWalletSlice(...a),
  ...createBalanceSlice(...a)
}));

mountStoreDevtool('Store', useAppStore);
