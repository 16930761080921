import { StateCreator } from 'zustand';
import { fetchInstances } from '../../helpers/utils';

export type tokenType = 'stkXPRT' | 'stkATOM';
export type TxnOption = 'migration' | 'remove-liquidity';

export type instanceTypes = {
  migrationAdmin: { [key in tokenType]: any };
  lpTokens: { [key in tokenType]: any };
  stakeLp: { [key in tokenType]: any };
};
export interface WalletSlice {
  metaMaskInfo: {
    walletConnected: boolean;
    address: string;
  };
  keplrInfo: {
    persistenceAddress: string;
    cosmosAddress: string;
  };
  instances: instanceTypes;
  connectMetaMask: (info: any) => void;
  connectKeplr: (pAddress: string, cAddress: string) => void;
  tokenType: tokenType;
  txnType: TxnOption;
  setTokenType: (tokenType: tokenType) => {};
  setTxnType: (value: TxnOption) => {};
  setContractInstances: (info: any) => void;
}

export const createWalletSlice: StateCreator<WalletSlice> = (set, get) => ({
  metaMaskInfo: {
    walletConnected: false,
    address: ''
  },
  keplrInfo: {
    persistenceAddress: '',
    cosmosAddress: ''
  },
  instances: {
    migrationAdmin: {
      stkATOM: '',
      stkXPRT: ''
    },
    lpTokens: {
      stkATOM: '',
      stkXPRT: ''
    },
    stakeLp: {
      stkATOM: '',
      stkXPRT: ''
    }
  },
  tokenType: 'stkATOM',
  txnType: 'migration',
  setContractInstances: (info: any) => {
    set({
      instances: info
    });
  },
  connectMetaMask: async (info: any) => {
    set({
      metaMaskInfo: info
    });
  },
  setTokenType: async (info: any) => {
    set({
      tokenType: info
    });
  },
  setTxnType: async (value: TxnOption) => {
    set({
      txnType: value
    });
  },
  connectKeplr: (pAddress: string, cAddress: string) => {
    set({
      keplrInfo: {
        persistenceAddress: pAddress,
        cosmosAddress: cAddress
      }
    });
  }
});
