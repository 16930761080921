export const MIGRATION_ADMIN = 'MigrationAdmin';
export const TOKEN_ABI = 'TokenABI';
export const STAKE_LP = 'StakeLP';
export const NO_OF_BLOCK_CONFIRMATIONS = 2;

export const CHAIN: any = {
  bech32Prefix: 'persistence',
  bech32Length: 38,
  Testnet: {
    networkID: 5,
    networkName: 'Goerli',
    networkFullName: 'Goerli Testnet',
    networkIdHex: '0x5',
    etherscanLink: 'https://goerli.etherscan.io/tx',
    alchemyAPI: 'https://eth-goerli.alchemyapi.io/v2/',
    appLink: 'https://testnet.cosmos.pstake.finance/wrap',
    transactionsLink: 'https://testnet.cosmos.pstake.finance/transactions',
    urlRedirect: {
      pStakeLPSection: 'https://testnet.cosmos.pstake.finance/stakelp-pool',
      stkATOM_ETH_pool: 'https://app.sushi.com/legacy/pool?chainId=5',
      bATOMSection: 'https://cosmos.pstake.finance/anchor'
    },
    SmartContracts: {
      MigrationAdmin: 'MigrationAdmin',
      TokenABI: 'TokenABI',
      StakeLP: 'StakeLP'
    },
    CONTRACT_ADDRESSES: {
      stkATOM: {
        MigrationAdmin: '0x28760A5E990b75b62050fEb13Ff0Fa85322f2261',
        TokenABI: '0x4F9Aa552a3A67E3453c50c2FfB20F5967e83335B',
        StakeLP: '0x1611B4591DDB0ab4a2869A063F47eEee28C55Ac4',
        StakeLpHolderAddress: '0xc4a270155E59A863E313eDbA5d8D973C6bF7Eaca',
        LPAddress: '0x195b2Ed7DfB2Bb19c63B8B06677D46934C0c4EeA'
      },
      stkXPRT: {
        MigrationAdmin: '0xa0FDf2BCCe8C58CC1bf45f4A90f92b571e944E32',
        TokenABI: '0x62D2e60d108B2Ac001252f3Af40D4aC9Ce740383',
        StakeLP: '0x1611B4591DDB0ab4a2869A063F47eEee28C55Ac4',
        StakeLpHolderAddress: '0xe7BB7b66d77E25656049e28638Df481c6D84072b',
        LPAddress: '0xF9a0483b7ACe75319cb78C0f1c69A04d581F3D1A'
      }
    }
  },
  Staging: {
    networkID: 1,
    networkIdHex: '0x1',
    networkName: 'Mainnet',
    networkFullName: 'Ethereum Mainnet',
    etherscanLink: 'https://goerli.etherscan.io/tx',
    alchemyAPI: 'https://eth-goerli.alchemyapi.io/v2/',
    appLink: 'https://testnet.cosmos.pstake.finance/wrap',
    transactionsLink: 'https://testnet.cosmos.pstake.finance/transactions',
    urlRedirect: {
      pStakeLPSection: 'https://cosmos.pstake.finance/stakelp-pool',
      stkATOM_ETH_pool:
        'https://app.sushi.com/legacy/remove/0x44017598f2AF1bD733F9D87b5017b4E7c1B28DDE/ETH?chainId=1',
      bATOMSection: 'https://cosmos.pstake.finance/anchor'
    },
    SmartContracts: {
      MigrationAdmin: 'MigrationAdmin',
      TokenABI: 'TokenABI',
      StakeLP: 'StakeLP'
    },
    CONTRACT_ADDRESSES: {
      stkATOM: {
        MigrationAdmin: '0x0D5Cd7F5E776b75903871FA3E1BFb093aB20e928',
        TokenABI: '0x195b2Ed7DfB2Bb19c63B8B06677D46934C0c4EeA',
        StakeLP: '0xA1cF35f35031c0f2a27283793bE23a22fd615F58',
        StakeLpHolderAddress: '0x8ad5628DBf3740c0e30f412E5fF29C7C28f591cE',
        LPAddress: '0x195b2Ed7DfB2Bb19c63B8B06677D46934C0c4EeA'
      },
      stkXPRT: {
        MigrationAdmin: '0x310d5ad7f7113be40e5ed7a052329b346af672b3',
        TokenABI: '0xF9a0483b7ACe75319cb78C0f1c69A04d581F3D1A',
        StakeLP: '0xA1cF35f35031c0f2a27283793bE23a22fd615F58',
        StakeLpHolderAddress: '0x2E16a16ceA3100aAc28759BC1dEA355271Ce3D69',
        LPAddress: '0xF9a0483b7ACe75319cb78C0f1c69A04d581F3D1A'
      }
    }
  },
  Mainnet: {
    networkID: 1,
    networkIdHex: '0x1',
    networkName: 'Mainnet',
    networkFullName: 'Ethereum Mainnet',
    etherscanLink: 'https://etherscan.io/tx',
    alchemyAPI: 'https://eth-goerli.alchemyapi.io/v2/',
    appLink: 'https://testnet.cosmos.pstake.finance/wrap',
    transactionsLink: 'https://testnet.cosmos.pstake.finance/transactions',
    urlRedirect: {
      pStakeLPSection: 'https://cosmos.pstake.finance/stakelp-pool',
      stkATOM_ETH_pool:
        'https://app.sushi.com/legacy/remove/0x44017598f2AF1bD733F9D87b5017b4E7c1B28DDE/ETH?chainId=1',
      bATOMSection: 'https://cosmos.pstake.finance/anchor'
    },
    SmartContracts: {
      MigrationAdmin: 'MigrationAdmin',
      TokenABI: 'TokenABI',
      StakeLP: 'StakeLP'
    },
    CONTRACT_ADDRESSES: {
      stkATOM: {
        MigrationAdmin: '0x0D5Cd7F5E776b75903871FA3E1BFb093aB20e928',
        TokenABI: '0x195b2Ed7DfB2Bb19c63B8B06677D46934C0c4EeA',
        StakeLP: '0xA1cF35f35031c0f2a27283793bE23a22fd615F58',
        StakeLpHolderAddress: '0x8ad5628DBf3740c0e30f412E5fF29C7C28f591cE',
        LPAddress: '0x195b2Ed7DfB2Bb19c63B8B06677D46934C0c4EeA'
      },
      stkXPRT: {
        MigrationAdmin: '0x310d5ad7f7113be40e5ed7a052329b346af672b3',
        TokenABI: '0xF9a0483b7ACe75319cb78C0f1c69A04d581F3D1A',
        StakeLP: '0xA1cF35f35031c0f2a27283793bE23a22fd615F58',
        StakeLpHolderAddress: '0x2E16a16ceA3100aAc28759BC1dEA355271Ce3D69',
        LPAddress: '0xF9a0483b7ACe75319cb78C0f1c69A04d581F3D1A'
      }
    }
  }
};
