import { StateCreator } from 'zustand';
import {
  fetchBondedLpTokenBalances,
  fetchEthShares,
  fetchLpTokenAllowances,
  fetchLpTokenBalances,
  fetchTokenBalances,
  getExchangeRate
} from '../../helpers/utils';
import { instanceTypes } from './walletSlice';
import { ethers } from 'ethers';
import { BigNumber } from '@ethersproject/bignumber';

export interface balances {
  stkATOM: {
    stkAtom?: number | string;
    pAtom?: number | string;
    lpTokens: BigNumber;
    bondedLp: BigNumber;
    ethShares: BigNumber;
    allowance: BigNumber;
  };
  stkXPRT: {
    stkXprt?: number | string;
    pXprt?: number | string;
    lpTokens: BigNumber;
    bondedLp: BigNumber;
    ethShares: BigNumber;
    allowance: BigNumber;
  };
}

export interface BalanceSlice {
  balances: balances;
  stkAtomCvalue: number;
  fetchBalances: (instances: instanceTypes, address: string) => Promise<any>;
}

export const createBalanceSlice: StateCreator<BalanceSlice> = (set, get) => ({
  balances: {
    stkATOM: {
      stkAtom: 0,
      pAtom: 0,
      lpTokens: ethers.utils.parseEther('0'),
      bondedLp: ethers.utils.parseEther('0'),
      ethShares: ethers.utils.parseEther('0'),
      allowance: ethers.utils.parseEther('0')
    },
    stkXPRT: {
      stkXprt: 0,
      lpTokens: ethers.utils.parseEther('0'),
      pXprt: 0,
      bondedLp: ethers.utils.parseEther('0'),
      ethShares: ethers.utils.parseEther('0'),
      allowance: ethers.utils.parseEther('0')
    }
  },
  stkAtomCvalue: 1,
  fetchBalances: async (instances: instanceTypes, address: string) => {
    const [balances, lpBalance, bondedLpBalance] = await Promise.all([
      fetchTokenBalances(instances, address),
      fetchLpTokenBalances(instances, address),
      fetchBondedLpTokenBalances(instances, address)
    ]);
    const exchangeRate = await getExchangeRate();
    const stkAtomLP = BigNumber.from(lpBalance.stkATOM).add(
      BigNumber.from(bondedLpBalance.stkATOM)
    );
    const stkXprtLP = BigNumber.from(lpBalance.stkXPRT).add(
      BigNumber.from(bondedLpBalance.stkXPRT)
    );
    const ethShares = await fetchEthShares(
      instances,
      address,
      stkAtomLP,
      stkXprtLP
    );
    const allowances = await fetchLpTokenAllowances(instances, address);
    set({
      balances: {
        stkATOM: {
          stkAtom: balances.stkATOM,
          pAtom: balances.pATOM,
          lpTokens: lpBalance.stkATOM,
          bondedLp: bondedLpBalance.stkATOM,
          ethShares: ethShares!.stkATOM,
          allowance: allowances.stkATOM
        },
        stkXPRT: {
          stkXprt: balances.stkXPRT,
          pXprt: balances.pXPRT,
          lpTokens: lpBalance.stkXPRT,
          bondedLp: bondedLpBalance.stkXPRT,
          ethShares: ethShares!.stkXPRT,
          allowance: allowances.stkXPRT
        }
      },
      stkAtomCvalue: exchangeRate
    });
  }
});
