import { StateCreator } from 'zustand';
export type txType = 'migrate' | 'unbondLp' | 'remove-liquidity';
export type txStatus = '' | 'failed' | 'pending' | 'success';

export interface TxnSlice {
  txnInfo: {
    type: txType;
    inProgress: boolean;
    status: txStatus;
  };
  bondTxn: {
    stepNumber: number;
    showModal: boolean;
  };
  setTxnInfo: (info: any) => void;
  setBondTxnInfo: (info: any) => void;
}

export const createTxnSlice: StateCreator<TxnSlice> = (set, get) => ({
  txnInfo: {
    type: 'migrate',
    inProgress: false,
    status: ''
  },
  bondTxn: {
    stepNumber: 0,
    showModal: false
  },
  setTxnInfo: (info: any) => {
    set({ txnInfo: info });
  },
  setBondTxnInfo: (info: any) => {
    set({ bondTxn: info });
  }
});
